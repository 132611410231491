import React from "react";
import * as classes from "./index.module.less";
import { Link } from "gatsby";
import Button from "components/shared/Button";

export default () => (
  <>
    <main className={classes.container}>
          <title>page not found</title>
          <h1 className={classes.hugeFont}>404</h1>
          <h2 className={classes.largeFont}>OOPS! PAGE NOT FOUND</h2>
          <p>
            Sorry, we couldn't find the page you were looking for. 
          </p>
          <Link to="/" className={classes.link}>
            <Button>Back to Home</Button>
          </Link>
    </main>
  </>
);
