import React, { useEffect, useState } from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Layout from "components/shared/Layout";
import NotFound from "components/not-found"
import LargeSpinner from "components/shared/LargeSpinner";
import CTA from "components/shared/CTA";

// markup
const NotFoundPage = () => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldRender(true);
    }, 2000);
  }, []);
  
  return shouldRender ? (
    <>
      <Meta 
        url="/404" 
        defaultTitle="Page not found..."
        defaultDescription="No description [...]"
      />
      <Nav />
      <Layout>
        <NotFound />
      </Layout>
      <CTA />
      <Footer />
    </>
  ) : (
    <LargeSpinner />
  );
};

export default NotFoundPage;
